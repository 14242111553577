
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class VersionUpdateDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() version!: string;

  features: { title: string; subtitle: string }[] = [
    {
      title: "Report sharing",
      subtitle:
        "Report sharing has been added back in. This can be done from either the report view page or on the stream reports page.",
    },
  ];

  fixes: { title: string; subtitle: string }[] = [];

  @Emit("close")
  close() {
    return;
  }
}
