
import store from "@/store";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
  @Prop() li!: boolean; // li = Logged In
  @Prop() username!: string;
  @Prop() darkModeButtonText!: string;
  @Prop() darkModeState!: boolean;
  @Prop() drawer!: boolean;
  @Prop() server!: string;
  @Prop() version!: string;

  @Emit("toggleDarkMode")
  toggleDarkMode() {
    return;
  }

  @Emit("logout")
  logout() {
    return;
  }

  @Emit("toggleDrawer")
  toggleDrawer() {
    return;
  }

  async changeRoute(newPath: string) {
    if (this.$route.path !== newPath) {
      await this.$router.push(newPath);
      this.toggleDrawer();
    }
    return;
  }

  items = [
    { title: "Dashboard", icon: "mdi-view-dashboard-outline", route: "/" },
    {
      title: "New Assessment",
      icon: "mdi-molecule-co2",
      route: "/assessment",
    },
    { title: "About", icon: "mdi-help-circle-outline", route: "/about" },
  ];
}
